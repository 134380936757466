import { bnHelper } from '@/helpers/bignumber-helper'
import { localdata } from '@/helpers/local-data'
import { TokenInfoModel } from '@/models/token-info-model'
import moment from 'moment'
import Moralis from 'moralis'
import Web3 from 'web3'

Moralis.initialize(process.env.VUE_APP_MORALIS_ID || '')
Moralis.serverURL = process.env.VUE_APP_MORALIS_URL || ''

export const moralisHelper = {
  getErc20TokenBalances: async (address: string, chain: 'eth' | 'bsc') => {
    const { tokens, time } = localdata.getAccountTokens(address, chain)
    let result = tokens
    if (!time || moment(time).add(5, 'minutes').isBefore(moment())) {
      try {
        result = await (Moralis.Web3API as any).account.getTokenBalances({ chain, address })
        localdata.saveAccountTokens(address, chain, result)
      } catch (error) {
        // using cache
      }
    }

    const results: TokenInfoModel[] = result
    return results.map((r) => ({
      ...r,
      token_address: Web3.utils.toChecksumAddress(r.token_address),
      decimals: +r.decimals,
      balance: bnHelper.fromDecimals(r.balance, r.decimals),
      chain,
    }))
  },
}
