import { appProvider } from '@/app-providers'
import { tokenHelper } from '@/blockchainHandlers/erc20-contract'
import { FarmContractSolana } from '@/blockchainHandlers/farm-contract-solana'
import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { moralisHelper } from '@/blockchainHandlers/moralis-helper'
import { priceHelper } from '@/blockchainHandlers/price-helper'
import { getTokenInfos, splPriceStore } from '@/blockchainHandlers/spl-token-helper'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { tierHelper, Zero } from '@/constants'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { localdata } from '@/helpers/local-data'
import { FixedPoolModel } from '@/models/fixed-pool-model'
import { TokenInfoModel } from '@/models/token-info-model'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import { poolsStore } from '@/modules/ido/stores/pools-store'
import { apiService } from '@/services/api-service'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { PublicKey } from '@solana/web3.js'
import { chain } from 'lodash'
import { ceil, flatten, orderBy, round, sum } from 'lodash-es'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment, { duration } from 'moment'
import web3 from 'web3'

// const a = moralisHelper
// let projects: any[] = []
// if (process.env.NODE_ENV === 'production') {
//   projects = require('../../../assets/projects.production.json')
// } else {
//   projects = require('../../../assets/projects.json')
// }

export interface InvestModel extends FixedPoolModel {
  projectName: string
  unicodeName: string
  contractAddress: string
  totalAmount: FixedNumber
  redeemedAmount: FixedNumber
  remainedAmount: FixedNumber
  salePrice: FixedNumber
  marketPrice?: FixedNumber
  value: FixedNumber
  remainedValue: FixedNumber
  percentage: FixedNumber
}

export interface WaggleNftKeyCardModel {
  wallet: string
  keyInfo: MarketKeyInfo
  poolStore: PoolStore
}

export class DashboardViewModel {
  @observable walletTokens: TokenInfoModel[] = []
  @observable walletAddresses: string[] = []
  @observable walletInvestors = {}

  @observable walletLoading = false
  @observable investLoading = false
  @observable farmLoading = false

  @observable farmStakedAmount = FixedNumber.from('0')
  @observable farmStakedValue = FixedNumber.from('0')
  @observable farmLockDuration = moment.duration(0, 'second') // in seconds
  @observable farmLastStakedTime: moment.Moment | null = null
  @observable registerWalletError = ''
  @observable addFirstWalletLoading = false
  @observable userTier = 'Staked'

  @observable itemsPerPage = 9

  //search
  @observable chainId = 56
  @observable searchWallet = ''
  @observable searchProject = ''

  //all investments
  @observable page = 1
  @observable walletOwner = 1

  //completed vesting
  @observable completedPage = 1

  @observable nftCards: WaggleNftKeyCardModel[] = []

  // chart
  @observable prices: any = []
  @observable xaxisCategories: any = []
  @observable selectedToken: any = {}
  @observable selectedDays = '1'

  @computed get shortNftCards() {
    return this.nftCards.slice(0, 3)
  }

  _disposers: IReactionDisposer[] = []

  constructor() {
    this.fetchPools()
    this._disposers = [
      reaction(
        () => this.walletAddresses,
        (arg) => {
          localdata.saveWalletAddress(walletStore.account, arg)
        }
      ),
      reaction(
        () => walletStore.account,
        () => {
          this.loadData()
          this.loadFarm()
          this.changeSelectedToken(this.tokenList[0])
          this.changeChart()
        },
        { fireImmediately: true }
      ),
    ]
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @asyncAction *fetchPools() {
    try {
      yield poolsStore.fetchPools()
    } catch (e) {
      //
    }
  }

  @action loadData() {
    this.walletAddresses = localdata.getWalletAddress(walletStore.account)
    this.loadWallets()
    this.loadInvestments()
    this.loadWalletInvestors()
  }

  @asyncAction *loadFarm() {
    this.farmLoading = true
    try {
      if (walletStore.account && walletStore.connectedSolProvider) {
        const handler = FarmContractSolana.getInstance()
        yield handler.load()
        // const { lp2UsdPrice } = yield handler.getFarmLiquidityAndApy()
        const { amount, lastStakeTime, lockDuration } = yield handler.getUserInfo(walletStore.account)
        const rewardPrice = yield splPriceStore.getRaydiumPriceByAddress(
          new PublicKey('5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E')
        )
        this.farmStakedAmount = amount
        this.farmStakedValue = amount.mulUnsafe(FixedNumber.from(rewardPrice.toString()))
        this.farmLockDuration = lockDuration
        this.farmLastStakedTime = lastStakeTime
        const lst = tierHelper.getTierList()
        const ter: any = lst.reverse().find((x) => this.farmStakedAmount.toUnsafeFloat() >= x.amount) || {}
        this.userTier = ter.name || 'Staked'
        if (ter.image) {
          this.tierImage = ter.image
        } else {
          this.tierImage = null
        }
      } else {
        this.farmStakedAmount = FixedNumber.from('0')
        this.tierImage = null
      }
    } catch (error) {
      console.error(error)
      snackController.error('There is error, please try again')
    } finally {
      this.farmLoading = false
    }
  }

  @observable tierImage: any = ''

  @action changeAddresses(addresses) {
    this.walletAddresses = addresses
  }

  @action addFirstWallet(input = '') {
    this.addFirstWalletLoading = true
    if (web3.utils.isAddress(input)) {
      const address = web3.utils.toChecksumAddress(input)
      this.walletAddresses = [address]
      this.registerWalletError = ''
    } else {
      try {
        const acc = new PublicKey(input)
        this.walletAddresses = [acc.toString()]
        this.registerWalletError = ''
      } catch (error) {
        this.registerWalletError = 'This is not valid address'
      }
    }
    this.addFirstWalletLoading = false
    if (this.walletAddresses.length > 0) {
      this.loadWallets()
      this.loadInvestments()
      this.loadWalletInvestors()
      this.loadFarm()
    }
  }

  async loadInvestments() {
    try {
      this.investLoading = true
      const projects: PoolStore[] = (await poolsStore.fetchPoolsIfNeed()) as any
      const wallets = this.walletAddresses
      const results: WaggleNftKeyCardModel[][] = await Promise.all(
        projects.map(async (poolStore) => {
          try {
            const contract = poolStore.contract
            if (contract) {
              await poolStore.loadData()

              return flatten(
                await Promise.all(
                  wallets.map(async (wallet) => {
                    const isSolidityWallet = web3.utils.isAddress(wallet)
                    let results: WaggleNftKeyCardModel[] = []
                    if (isSolidityWallet) {
                      if (poolStore.chain !== 'sol') {
                        const keyInfos = await contract.fetchUserMarketKeyInfos(wallet)
                        results = keyInfos.map((keyInfo) => ({
                          wallet,
                          keyInfo,
                          poolStore,
                        }))
                      }
                    } else {
                      if (poolStore.chain === 'sol') {
                        const keyInfos = await contract.fetchUserMarketKeyInfos(wallet)
                        results = keyInfos.map((keyInfo) => ({
                          wallet,
                          keyInfo,
                          poolStore,
                        }))
                      }
                    }
                    return results
                  })
                )
              )
            }
          } catch (error) {
            console.error(error)
          }
          return []
        })
      )
      this.nftCards = flatten(results)
      this.changeChart()
    } catch (err) {
      console.error(err)
      snackController.error('There is error, please try again')
    } finally {
      this.investLoading = false
    }
  }

  @computed get invests() {
    return chain(this.nftCards)
      .groupBy((c) => c.poolStore.pool.id)
      .map((cards) => {
        let totalAmount = FixedNumber.from('0')
        let redeemedAmount = FixedNumber.from('0')
        let remainedAmount = FixedNumber.from('0')
        const tokenName = cards[0].poolStore.tokenName
        for (const card of cards) {
          totalAmount = totalAmount.addUnsafe(card.keyInfo.boughtAmounts)
          redeemedAmount = redeemedAmount.addUnsafe(card.keyInfo.redeemedAmounts)
          remainedAmount = remainedAmount.addUnsafe(card.keyInfo.remain)
        }
        const salePrice = cards[0].poolStore.tradeValue
        const value = totalAmount.mulUnsafe(salePrice)
        const remainedValue = remainedAmount.mulUnsafe(salePrice)
        return {
          ...cards[0].poolStore.pool,
          tokenName,
          totalAmount,
          redeemedAmount,
          remainedAmount,
          salePrice,
          value,
          remainedValue,
        } as InvestModel
      })
      .value()
  }

  @computed get totalRemainedInvestValue() {
    return this.invests.reduce((prev, cur) => prev.addUnsafe(cur.remainedValue), FixedNumber.from('0'))
  }

  @computed get tokenList() {
    let list: any[] = []
    switch (walletStore.chainId) {
      case 1:
      case 3:
        // eth
        break
      case 56:
      case 97:
        list = [
          {
            id: 'binancecoin',
            tokenSymbol: 'BNB',
          },
          {
            id: 'faraland',
            tokenSymbol: 'FARA',
          },
        ]
        break
      case 101:
      case 102:
      case 103:
        list = [
          {
            id: 'solana',
            tokenSymbol: 'SOL',
          },
          {
            id: 'tribeland',
            tokenSymbol: 'TRBL',
          },
          {
            id: 'galaxy-war',
            tokenSymbol: 'GWT',
          },
        ]
        break
    }

    return list
  }

  @action.bound changeSelectedToken(token) {
    this.selectedToken = token
    this.changeChart()
  }

  @action.bound changeselectedDays(days) {
    this.selectedDays = days
    this.changeChart()
  }

  @asyncAction *changeChart() {
    this.prices = []
    const { xaxisCategories, series } = yield apiService.getTokenMarketData(
      this.selectedToken.id,
      'usd',
      this.selectedDays,
      this.selectedToken.tokenSymbol
    )
    this.prices = series
    this.xaxisCategories = xaxisCategories
  }

  @asyncAction *loadWallets() {
    if (this.walletLoading) return
    try {
      this.walletLoading = true
      const [bscResults, ethResults, solResults] = yield Promise.all([
        this.loadWalletSolidity('bsc'),
        this.loadWalletSolidity('eth'),
        this.loadWalletSolana(),
      ])
      let results: TokenInfoModel[] = [...bscResults, ...ethResults, ...solResults]
      results = orderBy(
        results.filter((t) => t.value && bigNumberHelper.gt(t.value, FixedNumber.from('0.1'))),
        (r) => r.value?.toUnsafeFloat(),
        'desc'
      )
      const totalValue = results.reduce((prev, cur) => prev.addUnsafe(cur?.value || Zero), Zero)
      this.walletTokens = results.map((r) => ({
        ...r,
        percentage: r.value?.divUnsafe(totalValue)?.mulUnsafe(FixedNumber.from('100')) || Zero,
      }))
    } catch (err) {
      console.error(err)
      snackController.error('There is error, please try again')
    } finally {
      this.walletLoading = false
    }
  }

  async loadWalletSolidity(chain: 'bsc' | 'eth') {
    const baseCoinName = chain === 'bsc' ? 'BNB' : 'ETH'

    const basCoin2Usd: FixedNumber = (await priceHelper.ofChain(chain)?.baseCoin2Usd()) || Zero
    const results: TokenInfoModel[] = []
    for (const address of this.walletAddresses.filter((x) => web3.utils.isAddress(x))) {
      const accountBalances: TokenInfoModel[] = await moralisHelper.getErc20TokenBalances(address, chain)
      const baseCoinBalance = (await priceHelper.ofChain(chain)?.baseCoinBalance(address)) || Zero
      if (!baseCoinBalance.isZero()) {
        accountBalances.push({
          token_address: '0x0',
          name: baseCoinName,
          decimals: 18,
          symbol: baseCoinName,
          balance: baseCoinBalance,
          chain: chain,
        })
      }
      for (const newBalance of accountBalances) {
        if (newBalance.token_address !== '0x0') {
          try {
            const tokenContract = tokenHelper.getContract(newBalance.token_address, chain)
            newBalance.balance = await tokenContract.getTokenAmount(address)
          } catch (error) {
            console.error(error)
          }
        }
        let found = false
        for (const oldBalance of results) {
          if (newBalance.token_address === oldBalance.token_address) {
            found = true
            oldBalance.balance = oldBalance.balance?.addUnsafe(newBalance?.balance || Zero) || Zero
            break
          }
        }
        if (!found) {
          results.push(newBalance)
        }
      }
    }
    await Promise.all(
      results.map(async (r) => {
        try {
          r.price = FixedNumber.from('0')
          r.value = FixedNumber.from('0')
          if (r.symbol === baseCoinName) {
            r.price = basCoin2Usd
            r.value = r.balance.mulUnsafe(basCoin2Usd)
          } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { price: bnb2Token, priceImpact } = await priceHelper
              .ofChain(chain)!
              .baseCoin2Token(r.token_address, +r.decimals, r.balance)
            if (bigNumberHelper.lt(priceImpact, FixedNumber.from('10'))) {
              r.value = r.balance?.mulUnsafe(r.price)
              if (bnb2Token && !bnb2Token.isZero()) {
                r.price = basCoin2Usd.divUnsafe(bnb2Token)
              }
            }
          }
        } catch (error) {
          console.error(error)
        }

        return r
      })
    )
    return results
  }

  async loadWalletSolana() {
    const solanaWallets = this.walletAddresses.filter((x) => !web3.utils.isAddress(x))
    const results: TokenInfoModel[] = []
    for (const wallet of solanaWallets) {
      const accountBalances = await getTokenInfos(wallet)
      for (const newBalance of accountBalances) {
        let found = false
        for (const oldBalance of results) {
          if (newBalance.token_address === oldBalance.token_address) {
            found = true
            oldBalance.balance = oldBalance.balance?.addUnsafe(newBalance?.balance || Zero) || Zero
            oldBalance.value = oldBalance.balance?.mulUnsafe(newBalance?.price || Zero) || Zero
            break
          }
        }
        if (!found) {
          results.push(newBalance)
        }
      }
    }
    return results
  }

  @asyncAction *loadWalletInvestors() {
    this.walletAddresses.forEach((walletAddress) => {
      this.walletInvestors[walletAddress] = 'loading'
    })
    for (let i = 0; i < this.walletAddresses.length; i++) {
      const walletAddress = this.walletAddresses[i]
      const investor = yield this.getInvestor(walletAddress)
      this.walletInvestors[walletAddress] = investor
    }
  }

  @asyncAction *getInvestor(walletAddress: string) {
    const users = yield apiService.users.find({
      username: walletAddress,
    })
    const user = users[0]
    if (user) return user.investor
    return null
  }

  @action.bound setSearchNetwork(val) {
    this.chainId = val
  }
  @action.bound setSearchWallet(val) {
    this.searchWallet = val
  }
  @action.bound setSearchProject(val) {
    this.searchProject = val
  }

  @computed get priceChartOptions() {
    return {
      theme: {
        mode: appProvider.themeType,
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      chart: {
        type: 'area',
        background: 'transparent',
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: '#595959',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: this.xaxisCategories,
      },
    }
  }

  @computed get validEthAddresses() {
    return this.walletAddresses.filter((address) => web3.utils.isAddress(address))
  }

  //TODO: Remove when sol network is valid
  @computed get walletSeries() {
    const result = this.top6Wallets.map((t) => t.value?.toUnsafeFloat())
    return result
  }

  @computed get investingProjects() {
    const investingProjects = this.nftCards.map((nft) => ({
      unicodeName: nft.poolStore.unicodeName,
      projectName: nft.poolStore.pool.name,
      projectLogo: nft.poolStore.logoImage,
    }))
    return [...new Map(investingProjects.map((item) => [item['unicodeName'], item])).values()]
  }
  @computed get recentNftCards() {
    return this.nftCards.slice(0, 3)
  }
  @computed get slicedNftCards() {
    const filteredNftCards = this.nftCards.filter((nft) => {
      let result = true
      if (this.searchProject && nft.poolStore.unicodeName !== this.searchProject) result = false
      if (this.searchWallet && nft.wallet.toLowerCase() !== this.searchWallet.toLowerCase()) result = false
      if (this.chainId && nft.poolStore.chainId !== this.chainId) result = false
      return result
    })
    return filteredNftCards.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
  }
  @computed get totalPage() {
    const totalCards = this.slicedNftCards.length
    if (!this.itemsPerPage) return 0
    if (totalCards % this.itemsPerPage == 0) return totalCards / this.itemsPerPage
    else return Math.floor(totalCards / this.itemsPerPage) + 1
  }

  @computed get walletPercent() {
    if (this.totalNetWorth === 0) return 0
    return (sum(this.walletSeries) / this.totalNetWorth) * 100
  }

  @computed get top6Wallets() {
    if (this.walletTokens.length <= 6) return this.walletTokens
    const top5 = this.walletTokens.slice(0, 5)
    const others = this.walletTokens.slice(5)
    return [
      ...top5,
      {
        symbol: 'Other',
        value: others.reduce((prev, cur) => prev.addUnsafe(cur.value || Zero), Zero),
        percentage: others.reduce((prev, cur) => prev.addUnsafe(cur.percentage || Zero), Zero),
      } as TokenInfoModel,
    ]
  }

  @computed get investSeries() {
    const result = this.top6Invest.map((t) => (t.value as any).toUnsafeFloat())
    return result
  }

  @computed get top6Invest() {
    if (this.invests.length <= 6) {
      const totalValue = this.invests.reduce((prev, cur) => prev.addUnsafe(cur.value || Zero), Zero)
      const invests = this.invests.map((r) => ({
        ...r,
        percentage: (r.value || Zero).divUnsafe(totalValue).mulUnsafe(FixedNumber.from('100')),
      }))
      return invests
    }
    const top5 = this.invests.slice(0, 5)
    const others = this.invests.slice(5)
    return [
      ...top5,
      {
        name: 'Other',
        value: others.reduce((prev, cur) => prev.addUnsafe(cur.value || Zero), Zero),
        percentage: others.reduce((prev, cur) => prev.addUnsafe(cur.percentage || Zero), Zero),
      },
    ]
  }

  @computed get investPercent() {
    if (this.totalNetWorth === 0) return 0
    return (sum(this.investSeries) / this.totalNetWorth) * 100
  }

  @computed get farmPercent() {
    if (this.totalNetWorth === 0) return 0
    return (this.farmStakedValue.toUnsafeFloat() / this.totalNetWorth) * 100
  }

  @computed get totalNetWorth() {
    return sum([...this.walletSeries, ...this.investSeries, this.farmStakedValue.toUnsafeFloat()])
  }

  @computed get isFarmStaked() {
    return !this.farmStakedAmount.isZero()
  }

  @computed get farmLockDays() {
    return this.farmLockDuration.asDays()
  }

  @computed get remainFarmLockDays() {
    if (this.farmLastStakedTime) {
      const unlockTime = this.farmLastStakedTime.clone().add(this.farmLockDuration)
      const diff = duration(unlockTime.diff(moment()))
      const diffDays = diff.asDays()
      if (diffDays < 0) {
        return { days: 0, message: 'Unlock now' }
      }
      let message = ''
      if (diffDays >= 1) message = `Unlock in ${round(diffDays, 0)} day(s)`
      else if (diff.asHours() >= 1) message = `Unlock in ${round(diff.asHours(), 0)} hour(s)`
      else message = `Unlock in ${ceil(diff.asMinutes(), 0)} minute(s)`
      return { days: diffDays, message }
    }
    return { days: 0, message: this.farmLoading ? 'Loading...' : 'You has not staked yet' }
  }

  @computed get remainFarmLockPercent() {
    const value = (this.farmLockDays - this.remainFarmLockDays.days) / (this.farmLockDays || 1)
    return value * 100
  }

  @computed get requiredFarmChainId() {
    return process.env.VUE_APP_FARM_NETWORK === 'production' ? 101 : 103
  }

  @computed get featuredPools() {
    return poolsStore.validPools.filter((p) => moment().isBefore(moment(p.pool.endDate)))
  }

  @computed get poolNews() {
    const newsList = [
      {
        id: 0,
        link: 'https://btcmanager.com/crypto-projects-raising-capital-2021/',
        image: require(`@/assets/images/btc-manager-news.svg`),
        title: 'How Crypto Projects Are Raising Capital in 2021?',
        webLogo: require(`@/assets/images/btc-manager-logo.svg`),
      },
      {
        id: 1,
        link: 'https://www.investing.com/news/cryptocurrency-news/waggle-network-raises-3m-to-build-multichain-marketplace-2623749',
        image: require(`@/assets/images/investing-news.svg`),
        title: 'Waggle Network Raises $3M To Build Multi-Chain Marketplace',
        webLogo: require(`@/assets/images/investing-logo.svg`),
      },
    ]
    const res =
      this.featuredPools.map((item) => {
        return {
          id: item.pool?._id,
          link: item.pool?.data?.twitterShareLink,
          image: item.pool?.data?.coverUrl,
          title: item.pool?.data?.twitterShareTitle,
          webLogo: item.pool?.logoUrl,
        }
      }) || []
    return [...res, ...newsList]
  }
}

export const dashboardViewModel = new DashboardViewModel()
